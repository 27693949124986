// CustomDatafeed.js
class CustomDatafeed extends window.Datafeeds.UDFCompatibleDatafeed {
    constructor(datafeedUrl, socketUrl, defaultProps) {
        super(datafeedUrl);
        this.socketUrl = socketUrl;
        this.defaultProps = defaultProps;
        this.subscriptions = {}; // Хранит подписки по subscribeUID
        this.currentSymbols = new Set(); // Текущие подписанные символы

        this.connectSocket();
    }

    connectSocket() {
        this.socket = new WebSocket(this.socketUrl);

        this.socket.onopen = () => {
            console.log(`WebSocket подключен: ${this.socketUrl}`);
            // Отправляем все текущие подписки при подключении
            this.currentSymbols.forEach(symbol => {
                this.socket.send(JSON.stringify({ action: 'subscribe', symbol }));
                console.log(`Resubscribed to symbol: ${symbol}`);
            });
        };

        this.socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                // Ожидаемый формат данных:
                // { symbol: 'BTCUSDT', time: 1730103090, open: 50000, high: 51000, low: 49500, close: 50500, volume: 100, interval: '1D' }

                if (data.time && data.open && data.high && data.low && data.close && data.volume) {
                    // Проходим по всем подпискам и вызываем колбэк, если символ и интервал совпадают
                    Object.values(this.subscriptions).forEach(sub => {
                        if (sub.symbol === data.symbol && sub.resolution === data.interval) {
                            const newBar = {
                                time: data.time * 1000, // Преобразование времени в миллисекунды
                                open: data.open,
                                high: data.high,
                                low: data.low,
                                close: data.close,
                                volume: data.volume,
                            };
                            console.log(`Updating chart for symbol: ${sub.symbol} with new bar:`, newBar);
                            sub.onRealtimeCallback(newBar);
                        }
                    });
                } else {
                    console.warn('Получены некорректные данные:', data);
                }
            } catch (error) {
                console.error('Ошибка при разборе сообщения WebSocket:', error);
            }
        };

        this.socket.onerror = (error) => {
            console.error('Ошибка WebSocket:', error);
        };

        this.socket.onclose = (event) => {
            console.log('WebSocket отключен:', event);
            this.socket = null;
            // При необходимости реализуйте логику переподключения
        };
    }

    // Переопределяем метод subscribeBars
    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) {
        if (this.subscriptions[subscribeUID]) {
            console.log(`Already subscribed with UID: ${subscribeUID}`);
            return;
        }

        const symbol = symbolInfo.ticker; // Используем ticker
        console.log(`Subscribing to symbol: ${symbol} with resolution: ${resolution}`);
        this.subscriptions[subscribeUID] = {
            symbol,
            resolution,
            onRealtimeCallback,
            onResetCacheNeededCallback,
        };

        // Добавляем символ в текущие подписки только один раз
        if (!this.currentSymbols.has(symbol)) {
            this.currentSymbols.add(symbol);

            // Отправляем сообщение о подписке
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.socket.send(JSON.stringify({ action: 'subscribe', symbol }));
                console.log(`Sent subscribe message for symbol: ${symbol}`);
            } else if (!this.socket) {
                this.connectSocket();
            }
        }
    }

    // Переопределяем метод unsubscribeBars
    unsubscribeBars(subscriberUID) {
        const subscription = this.subscriptions[subscriberUID];
        if (!subscription) {
            console.log(`No subscription found for UID: ${subscriberUID}`);
            return;
        }

        const { symbol } = subscription;
        console.log(`Unsubscribing UID: ${subscriberUID} from symbol: ${symbol}`);

        // Удаляем подписку
        delete this.subscriptions[subscriberUID];

        // Проверяем, есть ли ещё подписки на этот символ
        const stillSubscribed = Object.values(this.subscriptions).some(sub => sub.symbol === symbol);

        if (!stillSubscribed) {
            // Удаляем символ из текущих подписок и отправляем сообщение об отписке
            this.currentSymbols.delete(symbol);
            
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.socket.send(JSON.stringify({ action: 'unsubscribe', symbol }));
                console.log(`Sent unsubscribe message for symbol: ${symbol}`);
            }

            // Закрываем WebSocket, если нет активных подписок
            if (this.currentSymbols.size === 0 && this.socket) {
                this.socket.close();
                this.socket = null;
                console.log('No active subscriptions. WebSocket закрыт.');
            }
        }
    }

    // Метод для закрытия WebSocket при размонтировании компонента
    close() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
            console.log('WebSocket закрыт вручную.');
        }
    }

    // Переопределяем метод getServerTime (необязательно, но рекомендуется)
    getServerTime(callback) {
        callback(Date.now() / 1000);
    }

    // Добавьте другие необходимые переопределенные методы из UDFCompatibleDatafeed
}

export default CustomDatafeed;
