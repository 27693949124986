import React, { useState, useRef, useEffect } from "react";

import { CircularProgress } from '@mui/material';
import classes from "../styles/Graph.module.scss";
import useWebSocket from "react-use-websocket";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import logo from '../imgs/logo.png'
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { TVChartContainer } from './TradingView/components/TVChartContainer/index';

const AnnotationDialog = ({ open, onClose, onSave,buySell }) => {
  const [text, setText] = useState('');

  const handleSave = () => {
    onSave(text);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Enter the text for the annotation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the text you want to display for the annotation.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Annotation Text"
          fullWidth
          variant="standard"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};


function Graph({active , activeType,eurToUsd,usdToEur,interval,intervalLabel,theme,buy,sell, ticker}) {
    const {t} = useTranslation()
    
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [annotationText, setAnnotationText] = useState('');
    const handleAddTextAnnotation = () => {
        setDialogOpen(true);
      };
    
      const handleDialogClose = () => {
        setDialogOpen(false);
      };
    
      const handleSaveText = (text) => {
        setAnnotationText(text);
        return text;
      };
    const sciChartSurfaceRef = useRef();
    const sciChartSurfaceRefInd = useRef();
    const [candlestickChartSeries, setCandlestickChartSeries] = useState();
    const [ohlcChartSeries, setOhlcChartSeries] = useState();
    //code
    const [history,setHistory] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingChart, setIsLoadingChart] = useState(false);
    const socketUrl = 'wss://platform.zenitholdings.pro:8055/ws/plot/';
    const [socket,SetSocket] = useState('');

    // const { sendMessage, lastMessage, getWebSocket } = useWebSocket(socketUrl, {
    //     onOpen: () => sendMessage(socket), // Отправляем сообщение сразу после подключения
    //     shouldReconnect: (closeEvent) => true, // Автоматическое переподключение
    //     onMessage: (event) => {
    //     try {
    //     setIsLoading(false);
    //     const messageData = JSON.parse(event.data);
    //     const newCandle = Array.isArray(messageData) ? messageData[0] : messageData;
    //     const timeInMs = new Date(newCandle.time).getTime();
    //     if (!isNaN(timeInMs)) {
    //         const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    //         const localTime = timeInMs - timezoneOffset;
    //         const newCandleData = {
    //             time: localTime / 1000, // Преобразование в UNIX timestamp
    //             open: newCandle.open,
    //             high: newCandle.high,
    //             low: newCandle.low,
    //         close: newCandle.close,
    //         };
    //         setIsLoadingChart(false);
    //         // if (messageData.length > 1) {
    //         //   // Исторические данные
    //         // setIsLoadingChart(true);
    //         //     setHistory(messageData);
    //         //     if(sciChartSurfaceRef){
    //         //         const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
    //         //         // const lastDataPointTimestamp = history[history.length - 1].time; 
    //         //         // const zoomRangeSeconds = interval; // Переводим часы в секунды
    //         //         // const visibleRangeMin = lastDataPointTimestamp - zoomRangeSeconds;
    //         //         // const visibleRangeMax = lastDataPointTimestamp;
    //         //         // xAxis.visibleRange = new NumberRange(visibleRangeMin, visibleRangeMax);
    //         //         // sciChartSurfaceRef.current.zoomExtents();
    //         //         if (history.length > 100) {
    //         //             xAxis.visibleRange = new NumberRange(history.length - 100, history.length - 1);
    //         //             // sciChartSurfaceRef.current.zoomExtents();
    //         //         } else {
    //         //             xAxis.visibleRange = new NumberRange(0, history.length - 1);
    //         //             // sciChartSurfaceRef.current.zoomExtents();
    //         //         }
    //         //     }
    //         // } else {
    //         //     const lastCandleIndex = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.count() - 1;
    //         //     const newCandleTime = moment(new Date(newCandle.time)).utc(true).unix();
    //         //     const valueslow = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeLowValues(); 
    //         //     const valueshigh = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeHighValues(); 
    //         //     const valuesy = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.yValues; 
    //         //     const valuesx = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeXValues(); 
    //         //     const valuesclose = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeCloseValues(); 
    //         //     const valuesopen = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeOpenValues(); 
            
    //         //     const lastValueLineId = "last-value-line"; // Уникальный идентификатор для аннотации линии последнего значения
    //         //     const lastAxisMarkerId = "last-axis-marker"; // Уникальный идентификатор для AxisMarkerAnnotation
            
    //         //     // Функция для удаления существующих аннотаций с данными идентификаторами
    //         //     const removeLastValueAnnotations = () => {
    //         //         const existingLineAnnotation = sciChartSurfaceRef.current.annotations.getById(lastValueLineId);
    //         //         const existingAxisMarker = sciChartSurfaceRef.current.annotations.getById(lastAxisMarkerId);
    //         //         if (existingLineAnnotation) {
    //         //             sciChartSurfaceRef.current.annotations.remove(existingLineAnnotation);
    //         //         }
    //         //         if (existingAxisMarker) {
    //         //             sciChartSurfaceRef.current.annotations.remove(existingAxisMarker);
    //         //         }
    //         //     };
            
                
    //         //     if ((dayjs(newCandleTime) - dayjs(valuesx.get(lastCandleIndex))) === interval) {
    //         //         removeLastValueAnnotations(); // Удаление старых аннотаций
            
    //         //         sciChartSurfaceRef.current.annotations.add(
    //         //             new HorizontalLineAnnotation ({
    //         //                 id: lastValueLineId,
    //         //                 labelPlacement: ELabelPlacement.Axis,
    //         //                 showLabel: false,
    //         //                 stroke: newCandle.close < newCandle.open ? "#F63D68" : "#00B4A0",
    //         //                 strokeThickness: 2,
    //         //                 y1: secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close,
    //         //                 axisLabelFill: newCandle.close < newCandle.open ? "#F63D68" : "#00B4A0",
    //         //             }),
    //         //             new AxisMarkerAnnotation({
    //         //                 id: lastAxisMarkerId,
    //         //                 color: "#fff",
    //         //                 backgroundColor: newCandle.close < newCandle.open ? "#F63D68" : "#00B4A0",
    //         //                 y1: secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close,
    //         //                 fontSize: 12,
    //         //                 fontStyle: "Bold",
    //         //             })
    //         //         );
                    
    //         //         sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.append(
    //         //             valuesx.get(lastCandleIndex) + interval,
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.open*eurToUsd:newCandle.open,
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.high*eurToUsd:newCandle.high,
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.low*eurToUsd:newCandle.low,
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close
    //         //         );
    //         //         sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.update({
    //         //             index: +lastCandleIndex - 1,
    //         //             close: secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.open*eurToUsd:newCandle.open
    //         //         });
    //         //     } else if ((dayjs(newCandleTime) - dayjs(valuesx.get(lastCandleIndex))) < interval) {
    //         //         removeLastValueAnnotations(); // Удаление старых аннотаций
            
    //         //         let temp = {
    //         //             time: valuesx.get(lastCandleIndex),
    //         //             open: valuesopen.get(lastCandleIndex),
    //         //             close: valuesclose.get(lastCandleIndex),
    //         //             high: valueshigh.get(lastCandleIndex),
    //         //             low: valueslow.get(lastCandleIndex)
    //         //         };
    //         //         sciChartSurfaceRef.current.annotations.add(
    //         //             new HorizontalLineAnnotation ({
    //         //                 id: lastValueLineId,
    //         //                 labelPlacement: ELabelPlacement.Axis,
    //         //                 showLabel: false,
    //         //                 stroke: newCandle.close < temp.open ? "#F63D68" : "#00B4A0",
    //         //                 strokeThickness: 1,
    //         //                 y1: secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close,
    //         //                 axisLabelFill: newCandle.close < temp.open ? "#F63D68" : "#00B4A0",
    //         //             }),
    //         //             new AxisMarkerAnnotation({
    //         //                 id: lastAxisMarkerId,
    //         //                 color: "#fff",
    //         //                 backgroundColor: newCandle.close < temp.open ? "#F63D68" : "#00B4A0",
    //         //                 y1: secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close,
    //         //                 fontSize: 12,
    //         //                 fontStyle: "Bold",
    //         //             })
    //         //         );
    //         //         sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.removeAt(lastCandleIndex);
    //         //         sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.append(
    //         //             temp.time,
    //         //             temp.open,
    //         //             Math.max(secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.high*eurToUsd:newCandle.high, temp.high),
    //         //             Math.min(secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.low*eurToUsd:newCandle.low, temp.low),
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close
    //         //         );
    //         //     } else {
    //         //         removeLastValueAnnotations(); // Удаление старых аннотаций
            
                   
    //         //         sciChartSurfaceRef.current.annotations.add(
    //         //             new HorizontalLineAnnotation ({
    //         //                 id: lastValueLineId,
    //         //                 labelPlacement: ELabelPlacement.Axis,
    //         //                 showLabel: false,
    //         //                 stroke: newCandle.close < newCandle.open ? "#F63D68" : "#00B4A0",
    //         //                 strokeThickness: 1,
    //         //                 y1: secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close,
    //         //                 axisLabelFill: newCandle.close < newCandle.open ? "#F63D68" : "#00B4A0",
    //         //             }),
    //         //             new AxisMarkerAnnotation({
    //         //                 id: lastAxisMarkerId,
    //         //                 color: "#fff",
    //         //                 backgroundColor: newCandle.close < newCandle.open ? "#F63D68" : "#00B4A0",
    //         //                 y1: secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close,
    //         //                 fontSize: 12,
    //         //                 fontStyle: "Bold",
    //         //             })
    //         //         );
            
    //         //         sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.append(
    //         //             newCandleTime,
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.open*eurToUsd:newCandle.open,
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.high*eurToUsd:newCandle.high,
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.low*eurToUsd:newCandle.low,
    //         //             secureLocalStorage.getItem("user")?.Currency == "EUR"?newCandle.close*eurToUsd:newCandle.close
    //         //         );
            
    //         //         const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
    //         //         const visibleRange = xAxis.visibleRange;
    //         //         if (newCandleTime > visibleRange.max) {
    //         //             if (history.length > 100) {
    //         //                 xAxis.visibleRange = new NumberRange(history.length - 100, history.length - 1);
    //         //             } else {
    //         //                 xAxis.visibleRange = new NumberRange(0, history.length - 1);
    //         //             }
    //         //         }
    //         //         sciChartSurfaceRef.current.zoomExtents();
    //         //     }
    //         // }
    //     } else {
    //         console.error('Received time is invalid:', newCandle.time);
    //     }
    //     } catch (error) {
    //         console.error('Failed to parse incoming data', error);
    //     }
    //     },
    //     reconnectInterval: 500
    // });
    const [originalData, setOriginalData] = useState({
        valueslow: null,
        valueshigh: null,
        valuesclose: null,
        valuesopen: null,
        valuesx: null
    });

    
    
    
    useEffect(() => {
    // if(getWebSocket()){
        setIsLoadingChart(true);
        setIsLoading(true);
        // getWebSocket()?.close();
        axios.post(process.env.REACT_APP_test_url + '/api/users/socket',{
            message:`${active},${intervalLabel}`
        },  
        {
        headers:{
        'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
        }
        }
        ).then((res)=>{
            SetSocket(res.data);
            // sendMessage(res.data);
        })
    // }
    }, [active]);

    const handleBuy = () => {
        // Логика для Buy
        console.log('Buy action triggered');
    };

    const handleSell = () => {
        // Логика для Sell
        console.log('Sell action triggered');
    };


    return (
        <div className={classes.FullHeightChartWrapper}>
            {/* <IntervalSelector onIntervalChange={handleIntervalChange} interval={interval} activeType={activeType} t={t}/> */}
            {/* <div style={{width:"100%", height: "100%",display:isLoadingChart?"flex":"none",alignItems:"center",justifyContent:"center", backgroundColor:"transparent",position:"absolute",zIndex:"999",top:"1vh" }}>
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? '#383838' : '#383838'),
                        animationDuration: '1450ms',
                    }}
                    size={80}
                    thickness={4}
                />
            </div>  */}
            {/* <div style={{
                        position:"absolute", bottom:"0", left:"0",right:"0", top:"0", margin:"auto",
                        display:!isLoadingChart?"flex":"none",fontSize:"10.5vh",fontWeight:"bold",
                        fontFamily:"'Inter',sans-serif", height:"10vh",
                        opacity:"0.1",zIndex:"1",
                        color:"#54637A",justifyContent:"center", paddingRight:"0.2vw",
                        alignItems:"center", gap:"0.5vw", borderRadius:"2%",
                        background:"none", cursor:"default",userSelect:"none",pointerEvents:'none'}}>
                            <span>{active},{intervalLabel}</span>
            </div> */}
            <div style={{ display:"flex",  height: "100%",flex:1}}>
                <TVChartContainer theme={theme} ticker={ticker}/>
            </div>
        </div>
    );
}

export default Graph;